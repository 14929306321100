import { styled } from "goober";
import { Card } from "clutch/src/Card/Card.jsx";

import Container from "@/shared/ContentContainer.jsx";

export const PageWrapper = styled("div")`
  flex: 1;
  display: flex;
  flex-direction: column;
`;

export const Layout = styled(Container)`
  display: grid;
  gap: var(--sp-3);

  .grid {
    display: grid;
    grid-template-columns: repeat(12, 1fr);
    grid-auto-rows: max-content;
    gap: var(--sp-3);

    .span-quarter {
      grid-column: span 3;
    }
    .span-third {
      grid-column: span 4;
    }
    .span-half {
      grid-column: span 6;
    }
    .span-full {
      grid-column: span 12;
    }
  }

  .section-header {
    padding: var(--sp-1) var(--sp-4);
    margin-bottom: var(--sp-2);
    background: var(--shade8);
    border-radius: var(--br);
  }
`;

export const ChampionList = styled("ol")`
  display: grid;
  gap: var(--sp-3);

  &.truncated {
    display: flex;
  }
  &:not(.truncated) {
    grid-template-columns: repeat(auto-fit, minmax(7rem, 1fr));
  }

  .champion {
    --champ-color: var(--shade2);
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: var(--sp-2);
    min-width: calc(var(--sp-1) * 27);
    background: var(--shade7);
    text-align: center;
    border-radius: var(--br);
    padding: var(--sp-3) var(--sp-2);
    transition: background var(--transition);

    &[href] {
      &:hover {
        background: var(--shade6-50);
      }
    }

    .wins-info {
      > * {
        display: block;
      }

      .wl {
        color: var(--shade2);
      }
    }

    .champ-wr,
    .champ-lp {
      color: var(--champ-color);
    }
  }
`;

export const Roles = styled("ol")`
  display: flex;
  flex-direction: column;
  gap: var(--sp-3);
`;

export const Roleblock = styled(Card)`
  --role-color: var(--shade2);
  display: flex;
  justify-content: space-between;
  align-items: stretch;
  transition: background var(--transition);

  &:hover {
    background: var(--shade7);

    .role-icon {
      background: var(--shade5-50);
    }
    .champion {
      background: var(--shade3-15);
    }
  }

  .role-left {
    display: flex;
    gap: var(--sp-6);
  }
  .role-icon {
    display: grid;
    place-content: center;
    background: var(--shade6-50);
    width: var(--sp-13);
    height: var(--sp-13);
    border-radius: 50%;
    box-shadow: inset 0 2px 2px var(--shade2-15);
    transition: background var(--transition);
  }

  .role-title {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding-top: var(--sp-2);
  }
  .role-info {
    > * {
      display: block;
    }
  }
  .role-lp {
    color: var(--role-color);
  }
  .role-wl {
    color: var(--shade2);
  }
`;
