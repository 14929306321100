import React from "react";
import { useTranslation } from "react-i18next";
import { Card } from "clutch/src/Card/Card.jsx";

import { formatDuration, kdaColorStyle } from "@/app/util.mjs";
import { getWinRateColor } from "@/game-lol/utils/util.mjs";
import { TimeAgo } from "@/shared/Time.jsx";
import { getLocale } from "@/util/i18n-helper.mjs";

const StatsCards = ({ stats }) => {
  const { t } = useTranslation();

  if (!stats) return null;
  return (
    <>
      <Card classNameOuter="span-quarter">
        <h3
          className="type-h4"
          style={{ color: getWinRateColor((stats.wins / stats.games) * 100) }}
        >
          {(stats.wins / stats.games).toLocaleString(getLocale(), {
            style: "percent",
            minimumFractionDigits: 0,
            maximumFractionDigits: 0,
          })}
        </h3>
        <span className="type-body2 color-shade2">
          {t("common:stats.wr", "Winrate")}
        </span>
      </Card>
      <Card classNameOuter="span-quarter">
        <h3
          className="type-h4"
          style={{
            color: kdaColorStyle((stats.kills + stats.assists) / stats.deaths),
          }}
        >
          {((stats.kills + stats.assists) / stats.deaths).toLocaleString(
            getLocale(),
            {
              maximumFractionDigits: 2,
              minimumFractionDigits: 2,
            },
          )}
        </h3>
        <span className="type-body2 color-shade1">
          {t("common:stats.kda", "KDA")}
        </span>
      </Card>
      <Card classNameOuter="span-quarter">
        <h3 className="type-h4">
          {formatDuration(
            (stats.minutesPlayed * 60 * 1000) / stats.games,
            "mm:ss",
          )}
        </h3>
        <span className="type-body2 color-shade2">
          {t("lol:avgGame", "Avg. Game")}
        </span>
      </Card>
      <Card classNameOuter="span-quarter">
        <h3 className="type-h4">
          {(stats.damageToChampions / stats.minutesPlayed).toLocaleString(
            getLocale(),
            {
              maximumFractionDigits: 1,
              minimumFractionDigits: 1,
            },
          )}
        </h3>
        <span className="type-body2 color-shade1">
          {t("lol:stat.dmgPerMin", "Dmg/Min.")}
        </span>
      </Card>
      <Card classNameOuter="span-quarter">
        <h3 className="type-h4">
          {(stats.damageToChampions / (stats.goldSpent / 100)).toLocaleString(
            getLocale(),
            {
              maximumFractionDigits: 0,
              minimumFractionDigits: 0,
            },
          )}
        </h3>
        <span className="type-body2 color-shade1">
          {t("lol:stat.dmgPer100GoldSpent", "Damage per 100 gold spent")}
        </span>
      </Card>
      <Card classNameOuter="span-quarter">
        <h3 className="type-h4">
          {(stats.visionScore / stats.games).toLocaleString(getLocale(), {
            maximumFractionDigits: 0,
            minimumFractionDigits: 0,
          })}
        </h3>
        <span className="type-body2 color-shade1">
          {t("lol:specificMatchup.visionScore", "Vision Score")}
        </span>
      </Card>
      <Card classNameOuter="span-quarter">
        <h3 className="type-h4">
          {(stats.minionsKilledTotal / stats.minutesPlayed).toLocaleString(
            getLocale(),
            {
              maximumFractionDigits: 1,
              minimumFractionDigits: 1,
            },
          )}
        </h3>
        <span className="type-body2 color-shade1">
          {t(
            "overlays:benchmarkingAssistance.feature.csPerMin",
            "CS per Minute",
          )}
        </span>
      </Card>
      <Card classNameOuter="span-quarter">
        <h3 className="type-h4">
          <TimeAgo date={stats.lastPlayed} />
        </h3>
        <span className="type-body2 color-shade1">
          {t("common:lastPlayed", "Last Played")}
        </span>
      </Card>
    </>
  );
};

export default StatsCards;
